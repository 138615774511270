import * as React from 'react'
import * as ReactDOM from 'react-dom'
import './styles/reset.scss'
import JumboTitle from 'src/elements/JumboTitle'
import Body from 'src/elements/Body'
import Text from 'src/elements/Text'
import Place from 'src/elements/Place'

const App: React.FunctionComponent = () => (
    <Body>
        <JumboTitle>Daniel's Guide To Porto</JumboTitle>
        <Text>
            <p>Since people often ask me, this is my (always changing) guide to Porto.</p>
        </Text>
        <Place
            title="Capa Negra"
            description="There are many places where to eat the typical Francesinha (and you should eat one), but this is one of my favourite places. Ask with egg and fries."
            mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3004.179000708333!2d-8.632700084770047!3d41.15244527928642!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd246f833884640d%3A0x34a3371d35c9e1d5!2sCapa%20Negra%20II!5e0!3m2!1sen!2snl!4v1569148395020!5m2!1sen!2snl"
        />
        <Place
            title="Casa da Música"
            description={
                '"The House of Music" is a very interesting building dedicated to, well, music. The guided tour is well worth the visit if you\'re into music - I won\'t spoil it here.'
            }
            mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3003.88406809017!2d-8.632884684769888!3d41.158880979285826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd246500dce643cf%3A0xc35ebaec444540ed!2sCasa%20da%20M%C3%BAsica!5e0!3m2!1sen!2snl!4v1569148723325!5m2!1sen!2snl"
        />
        <Place
            title="I used to live here"
            description="Can you believe it?"
            mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d751.173055419227!2d-8.617149564178325!3d41.14124435500734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd24651fcd29ca45%3A0xaed9d78d4002f4f1!2sR.%20de%20S%C3%A3o%20Francisco%2036%2C%204050-253%20Porto%2C%20Portugal!5e0!3m2!1sen!2snl!4v1569148809883!5m2!1sen!2snl"
        />
        <Place
            title="São Bento station"
            description="Just get in to see the beautiful tiles, they're really nice."
            mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2813.1146941789375!2d-8.614829666798318!3d41.143452604266145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2464e4756ff947%3A0x124f921e49a8773e!2sS%C3%A3o%20Bento%20Station!5e0!3m2!1sen!2snl!4v1569148934793!5m2!1sen!2snl"
        />
        <Place
            title="Bolsa Palace"
            description="Really impressive building, worth the tour (I'm not that familiar with it, let me know if you liked it)."
            mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2932.6818150916547!2d-8.616503855714313!3d41.14107945014594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x69324171e40cd951!2sBolsa%20Palace!5e0!3m2!1sen!2snl!4v1569149180189!5m2!1sen!2snl"
        />
        <Place
            title="Hard Club"
            description="Nice restaurant, bar and concert hall. It used to be a market."
            mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2932.6818150916547!2d-8.616503855714313!3d41.14107945014594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd246518c50af2e1%3A0xe6b342cc547ffc85!2sHard%20Club!5e0!3m2!1sen!2snl!4v1569149223840!5m2!1sen!2snl"
        />
        <Place
            title="Ribeira (neighbourhood)"
            description="Go there for a genuine Porto experience. See the Dom Luiz I bridge, architected by Théophile Seyrig, a disciple of Gustave Eiffel (the one from the tower)."
            mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3953.26786020295!2d-8.61774222376204!3d41.14138499812721!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2464e0ec90084f%3A0xe4533a3083ad4f29!2sBairro%20da%20Ribeira!5e0!3m2!1sen!2snl!4v1569149684950!5m2!1sen!2snl"
        />
        <Place
            title="Clérigos Tower"
            description="A cool tower architected by Eiffel. There's a church on the other side - you might want to take a peak on the inside."
            mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1669.7100742724551!2d-8.6148413961567!3d41.14657268112015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2464e251ced649%3A0x425ec51327127c21!2sCl%C3%A9rigos%20Tower!5e0!3m2!1sen!2snl!4v1569152388756!5m2!1sen!2snl"
        />
        <Place
            title="Livraria Lello"
            description="The most impressive bookstore you'll ever see in your life. Unfortunately, due to having been featured on Harry Potter or having inspired Harry Potter or whatever (J.K. has a connection with Porto through her husband) it atracts a murder of tourists causing long lines."
            mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3004.4328460335773!2d-8.616963284770211!3d41.14690547928701!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2464e28c5e9a77%3A0x7f08405ee8a1e44f!2sLivraria%20Lello!5e0!3m2!1sen!2snl!4v1569175443121!5m2!1sen!2snl"
        />
        <Place
            title="Galerias de Paris"
            description="These two streets have become the new bar area. I'm linking here to what I believe was the first bar which attracted all the other ones"
            mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1502.211364168644!2d-8.615410341863768!3d41.14712629482172!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2464e340499ca5%3A0x86de5dd42c7baf8!2sPlan%20B%20Club%20-%20Port!5e0!3m2!1sen!2snl!4v1569152657632!5m2!1sen!2snl1"
        />
        <Place
            title="Carmo/Lions Square"
            description={
                "There's a lot to see. The Church of Carmo (with the beautiful tiles on the side wall). The church of the Barefeet Carmelitas. The lions' square with the lions fountain, symbol of the Sciences Faculty of the University of Porto. The central building of the University of Porto - there's a museum of the old Sciences Faculty (which used to be in that building) inside worth a look. In the square there's also Piolho, the most typical student's cafe - inside you can find on the walls tributes by groups of students to Piolho. \"Piolho\" means \"head lice\", by the way."
            }
            mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1169.1105801779715!2d-8.617319848893324!3d41.14745675082907!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd24651d68ecd2af%3A0x93564a9dc1cb3c67!2sCarmo!5e0!3m2!1sen!2snl!4v1569150405789!5m2!1sen!2snl"
        />
        <Place
            title="Carlos Alberto Square"
            description="See the tiles on the side wall of the Carmo church. There's the Carlos Alberto theatre, with more alternative theatre shows. There's also the begining of the Cedofeita street, a commercial stret."
            mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3295.2835409835625!2d-8.617038269810266!3d41.14785361647597!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2464e2abc95e31%3A0x2571a141c000f988!2sPra%C3%A7a%20de%20Carlos%20Alberto!5e0!3m2!1sen!2snl!4v1569150374077!5m2!1sen!2snl"
        />
        <Place
            title="Cordoaria garden"
            description="These gardens have some intriguing sculptures. It used to be known for a sex worker hub. Not anymore. On the other side of the street there's the Centre of Photograpy in the old prision, and behind that one there's the Monestry of São Bento da Vitória, which is one of the three venues of the National Theatre. Brhind that one there's a sightseeing place: Miradouro de São Bento da Vitória."
            mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2233.996675771377!2d-8.617610761228812!3d41.14523074271644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd24651d9ad17151%3A0x902a6870c2c17bf4!2sCordoaria&#39;s%20Garden!5e0!3m2!1sen!2snl!4v1569150544715!5m2!1sen!2snl"
        />
        <Place
            title="Mercado do Bolhão"
            description="The most genuine Porto Market. The saleswomen will flirt with you. Eat there if you dare (no, really, there are nice places to eat)."
            mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3004.34985611804!2d-8.609658484770165!3d41.148716679286686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2464e525d70c15%3A0xf17622db196e7ce9!2sMercado%20do%20Bolh%C3%A3o!5e0!3m2!1sen!2snl!4v1569152759959!5m2!1sen!2snl"
        />
        <Place
            title="Rua de Santa Catarina"
            description="This is an interesting commercial street. The place in the map bellow (Fnac) has a clock on the corner. On the hour (maybe also on the half hour), the bell tree of the clock rings and tiny figures come out and do a little dance."
            mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1608.1789954325416!2d-8.606834776713228!3d41.14681108826568!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2464e5a70c9b45%3A0xb0f2dfcbfca89bfb!2sFnac!5e0!3m2!1sen!2snl!4v1569152864459!5m2!1sen!2snl"
        />
        <Place
            title="Praça da Batalha/São João National Theatre"
            description="Another place where to stop and look if you're around. It features the Church of Saint Ildefonso, which looks very nice from the outside."
            mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2308.7813116090997!2d-8.607799369251355!3d41.14513576570328!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2464e6783e9b1f%3A0xb97ba181af122779!2sBatalha%20Square!5e0!3m2!1sen!2snl!4v1569153169062!5m2!1sen!2snl"
        />
        <Place
            title="São Lázaro Square"
            description="Nice place to stop for a beer if you're around. One of the streets has the Faculty of Arts."
            mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1914.689536674564!2d-8.60545702625666!3d41.14609188282638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2464ef2191b9e3%3A0xb0d8f9ac36dd3f2c!2sJardim%20de%20S%C3%A3o%20L%C3%A1zaro!5e0!3m2!1sen!2snl!4v1569153040285!5m2!1sen!2snl"
        />
        <Place
            title="Bom Sucesso Market"
            description="It's always worth seeing a Portuguese market. It's renewed, so it diverted from the original look on the inside, but it's still nice."
            mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5928.925533946925!2d-8.633471095663074!3d41.153919282083656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd246508f4545b65%3A0xb332c00c49ffce31!2sMercado%20Bom%20Sucesso!5e0!3m2!1sen!2snl!4v1569149603237!5m2!1sen!2snl"
        />
        <Place
            title="Crystal Palace gardens"
            description="If you're into gardens. The palace itself is gone, that huge building is an arena/convention centre."
            mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13066.317510580528!2d-8.636331266339514!3d41.15345923634903!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd24651aa3a065a3%3A0x646cba8e35b01d38!2sJardins%20do%20Pal%C3%A1cio%20de%20Cristal!5e0!3m2!1sen!2snl!4v1569149549835!5m2!1sen!2snl"
        />
        <Place
            title="Tram Museum"
            description="Tram museum."
            mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8294.045989195563!2d-8.632654058751804!3d41.14763568353842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd24650d6e5d4f6d%3A0xb0933e834cc8bbfc!2sTram%20Museum!5e0!3m2!1sen!2snl!4v1569149332236!5m2!1sen!2snl"
        />
        <Place
            title="Serralves Museum"
            description="Amazing contemporary art museum, featuring two building and a huge park with sculptures. Well worth seeing."
            mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d18605.60266633658!2d-8.668682939429!3d41.158753104954684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd246580a87750d3%3A0xd35d20c552dac5f7!2sMuseu%20Serralves!5e0!3m2!1sen!2snl!4v1569153261178!5m2!1sen!2snl"
        />
        <Place
            title="Matosinhos"
            description="Hop in the metro to Matosinhos (leave in Brito Capelo), head to the Serpa Pinto street (or the other streets paralell to that) and get yourself the best grilled fish and seafood you've ever had. Beach is nearby."
            mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5138.317197724323!2d-8.69599832779547!3d41.18309125803738!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd246f306c450451%3A0x84f6e8b85f23a6a7!2sBrito%20Capelo!5e0!3m2!1sen!2snl!4v1569153361295!5m2!1sen!2snl"
        />
    </Body>
)

ReactDOM.render(<App />, document.getElementById('root'))
